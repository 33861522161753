/* global $ */
import Vue from 'vue';
import App from './App.vue';

Vue.config.productionTip = false;

if($('.treeselect').length > 0) {
    $('.treeselect').each(function () {
        new Vue({
            render: h => h(App)
        }).$mount(this);
    });
}
