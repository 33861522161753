<template>
    <div id="app">
        <treeselect v-model="value"
                    :multiple="true"
                    :clearable="true"
                    :open-on-click="true"
                    :disableFuzzyMatching="true"
                    :limit="config.limit"
                    :max-height="config.maxHeight"
                    :limitText="count => `und ${count} weitere`"
                    :loadingText="'Laden...'"
                    :noChildrenText="'Keine weiteren Optionen'"
                    :noOptionsText="'Keine Optionen'"
                    :noResultsText="'Keine Ergebnisse gefunden...'"
                    :options="options"
                    :joinValues="true"
                    :name="config.inputName"
                    placeholder="Kategorie auswählen..."
                    search-nested
                    :valueFormat="'id'"
                    :valueConsistsOf="'ALL'"
        />
    </div>
</template>

<script>
    /* global $ */
    import Treeselect from '@riophae/vue-treeselect';
    import '@riophae/vue-treeselect/dist/vue-treeselect.css';
    import axios from 'axios';

    export default {
        name: 'App',
        components: {
            Treeselect
        },
        data () {
            return {
                // define the default value
                value: null,
                valueD: [],
                // define options
                options: [],

                config: {
                    link: 'https://werbejunkies.de/pav/api-category.php',
                    modul: 'loadArrayCategorys',
                    inputName: 'categorys',
                    limit: 10,
                    maxHeight: 200
                }
            };
        },
        created () {
            this.fetchData();
        },
        mounted () {
            let data = $(this.$el).closest('.treeselect-wrapper').data();
            this.config = $.extend(this.config, data);
            if (data.value !== '') {
                this.valueD = data.value.toString().split(',');
            }

        },
        watch: {
            value: function () {
                let inputName = this.config.inputName;
                setTimeout(function () {
                    $('[name="' + inputName + '"]').trigger('change');
                    if(inputName === 'filterAdsKategorie') {
                        $('#filterAdsKategorieDummy').trigger('change');
                    }
                }, 200);
            }
        },
        methods: {
            fetchData () {
                let _this = this;
                axios.post(_this.config.link, {
                    modus: _this.config.modul
                })
                  .then(function ({data}) {
                      _this.options = data;
                      _this.value = _this.valueD;
                  })
                  .catch(function (error) {
                      window.console.log(error);
                  });
            }
        }
    };
</script>
